<template>
  <div class="body">
    <div style="min-height: 90vh" id="app">
      <section
        ref="topHeader"
        style="top: 0; width: 100%; max-width: 768px; z-index: 1000"
        class="top-header"
        v-bind:class="canStick()"
        v-if="current_page != 'print-game'"
        v-show="current_page != 'casinogames'"
      >
        <div
          class="d-flex m-0 align-items-center header"
          style="background-color: var(--white)"
          v-if="!$route.meta.hideMenu"
        >
          <div
            class="d-flex align-items-center px-0 pt-1 pb-1 m-0 text-center logo"
          >
            <router-link to="/">
              <img
                style="float: left; height: 20px; width: 88.25px"
                src="/img/chopLifeLogo.svg"
                class="top-header-logo img-fluid"
                alt="Chopbet Logo"
              />
            </router-link>
            <img
              style="height: 16px; width: 16px"
              src="/img/ivory_coast.svg"
              class="top-header-logo img-fluid"
              alt="ivory coast flag"
            />
          </div>
          <div class="p-0 login">
            <div
              v-if="profile"
              class="d-flex depo text-center justify-content-around"
            >
              <router-link
                class="mr-1"
                style="padding: 3px; border-radius: 5px"
                to="/inbox"
              >
                <img src="/menu/chat-icon.png" class="wallet-icon" />
              </router-link>

              <router-link
                class="mr-1"
                style="
                  background-color: var(--darker-gray-white);
                  padding: 3px;
                  border-radius: 5px;
                "
                to="/my-account"
              >
                <img src="/menu/coin.png" class="wallet-icon" />
                <span class="wallet-balance text-yellow wallet-text">
                  <strong><span v-text="bal"></span></strong>
                </span>
              </router-link>

              <router-link
                class="mr-1"
                style="padding: 3px; border-radius: 5px"
                to="/deposit-funds"
              >
                <img src="/menu/plus-icon.png" class="wallet-icon" />
                <span class="wallet-balance text-dark wallet-text">
                  <strong><span>Deposit</span></strong>
                </span>
              </router-link>
            </div>

            <div v-else class="d-flex align-items-center text-center d-none">
              <LanguageToggle />
              <router-link
                to="/login"
                class="header-login-button"
                style="color: #000 !important"
              >
                {{ $t("login") }}
              </router-link>
              <router-link
                to="/join"
                class="header-join-button"
                style="color: #fff !important"
              >
                {{ $t("join") }}
              </router-link>
            </div>
          </div>
        </div>
      </section>

      <section
        style="position: relative; z-index: 500"
        class="feature-menu"
        v-if="!$route.meta.hideMenu"
      >
        <div class="icons-wrapper top-menu-items">
          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('sport')"
          >
            <router-link to="/">
              <div class="icon-container">
                <img src="/img/TopNav/football.svg" class="menu-icon-svg" />
              </div>
              <span class="text-dark txt"> {{ $t("football") }} </span>
            </router-link>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('home')"
          >
            <router-link to="/crash">
              <div class="icon-container">
                <img src="/img/TopNav/crash.svg" class="menu-icon-svg" />
              </div>
              <span class="text-dark txt"> {{ $t("crash") }}</span>
            </router-link>
          </div>

          <div
            v-for="(game, index) in aviatorGame"
            v-bind:key="getKey(index)"
            class="feature-icon-item-wrapper text-center"
          >
            <router-link
              :to="{
                name: 'aviator',
                params: {
                  IsDemo: 0,
                  providerID: game.providerID,
                  gameName: game.gameName,
                  gameID: game.gameID,
                },
              }"
            >
              <div class="crash-icon-container">
                <img v-bind:src="game.image" class="menu-icon-svg" />
              </div>
              <span class="text-dark txt">{{ game.gameName }}</span>
            </router-link>
          </div>

          <div
            v-for="(game, index) in homePageGame"
            v-bind:key="getKey(index)"
            class="feature-icon-item-wrapper text-center"
          >
            <router-link
              :to="{
                name: 'casinogames',
                params: {
                  IsDemo: 0,
                  providerID: game.providerID,
                  gameName: game.gameName,
                  gameID: game.gameID,
                },
              }"
            >
              <div class="crash-icon-container">
                <img v-bind:src="game.image" class="menu-icon-svg" />
              </div>
              <span class="text-dark txt">{{ game.gameName }}</span>
            </router-link>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('casino')"
          >
            <router-link to="/casino">
              <div class="icon-container">
                <img src="/img/TopNav/casino.svg" class="menu-icon-svg" />
              </div>
              <span class="text-dark txt">{{ $t("casino") }}</span>
            </router-link>
          </div>

          <div
            class="feature-icon-item-wrapper text-center"
            v-bind:class="isActive('gifts')"
          >
            <router-link to="/gifts">
              <div class="icon-container">
                <img src="/img/TopNav/promo.png" class="menu-icon-svg" />
              </div>
              <span class="text-dark txt">{{ $t("promos") }} </span>
            </router-link>
          </div>
        </div>
      </section>

      <!--A-Z Menu-->
      <div id="menu-modal" class="faso-menu-modal" style="z-index: 99999">
        <!-- Modal content -->
        <div
          style="background-color: var(--white)"
          class="faso-menu-modal-content"
        >
          <section style="background-color: var(--white)" class="w-100 menu-bg">
            <div class="row m-0 p-3 side-menu-header" @click="goTo('home')">
              <img
                style="width: 12px"
                src="/img/exit.svg"
                alt="Chopbet close menu"
              />
              <div>
                <img
                  style="width: 88.25px"
                  src="/img/chopLifeLogo.svg"
                  class="top-header-logo img-fluid"
                  alt="Chopbet Logo"
                />
              </div>
              <div class="text-right"></div>
            </div>

            <!-- <hr class="m-1" /> -->
            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/soccer-red.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Sports</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/live" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/live.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Live Games</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/live" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img
                    src="/menu/controller-icon.png"
                    class="side-icon-svg mr-2"
                  />
                  <span class="text-dark">
                    <strong>Virtuals</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/casino" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/spades.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Casino</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/jackpot" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/crown.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Jackpots</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/leaderboard" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/leaderboard.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Leaderboards</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              style="border-bottom: solid var(--greyText) 1px"
              class="d-flex justify-content-left px-3 py-2 flatlink-div"
            >
              <span class="text-dark">Player Account</span>
            </div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/my-bets" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/time.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Bet History</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/settings" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/user-icon.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>My Account</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/inbox" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/chat.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Inbox</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/language" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/globe.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Language</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/my-bets" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/paint.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Display</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 32px; height: 32px"
                src="/img/dark_mode.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              style="border-bottom: solid var(--greyText) 1px"
              class="d-flex justify-content-left px-3 py-2 flatlink-div"
            >
              <span class="text-dark">Help</span>
            </div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/customer-support" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/support.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Customer Support</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/responsible-gaming" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/18.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Responsible Gaming</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/faq" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/faq.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Frequently Asked Questions</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/terms" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/list.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Terms and Conditions</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center flatlink-div"
            >
              <router-link to="/privacy" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/privacy.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Privacy Policy</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 16px; height: 16px"
                src="/img/arrow-right.svg"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div
              class="d-flex justify-content-between align-items-center px-3 py-1 flatlink-div mt-2"
            >
              <router-link to="/rules" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/menu/rules.png" class="side-icon-svg mr-2" />
                  <span class="text-dark">
                    <strong>Betting Rules</strong>
                  </span>
                </a>
              </router-link>
              <img
                style="width: 10px; height: 10px; margin-bottom: 10px"
                src="/menu/right.png"
                class="side-icon-svg"
              />
            </div>

            <div style="border-bottom: solid var(--greyText) 1px"></div>

            <div class="d-flex justify-content-left flatlink-div">
              <a @click="logout" class="flat-link">
                <!-- <img src="/menu/power.png" class="side-icon-svg mr-2" /> -->
                <span class="text-danger">Log out</span>
              </a>
            </div>
          </section>
        </div>
      </div>
      <!--End A-Z Menu-->
      <span id="menu-init" class="hidden"></span>
      <span class="faso-menu-close hidden"></span>

      <router-view></router-view>

      <div
        class="depo-widget d-none"
        id="dpo-widg"
        v-show="current_page != 'deposit'"
      >
        <span id="dpo-close" class="text-dark" @click="closeWidget()"
          ><i class="bi bi-x-lg"></i
        ></span>
        <router-link
          to="/deposit"
          class="deposit-link"
          v-show="current_page != 'deposit'"
        >
          <img src="/img/depo.webp" class="depo-widg" />
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
//import mqtt from "mqtt";
import Footer from "./components/Footer.vue";
import axios from "@/services/api";
import store from "./store/store";
import LanguageToggle from "./components/ui/LanguageToggle.vue";

export default {
  store,
  name: "app",
  data: function () {
    return {
      currency: process.env.VUE_APP_CURRENCY,
      current_menu: "",
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: "",
      message: "Please enter amount you want to deposit",
      aviatorGame: [
        {
          providerID: 7,
          gameID: "aviator",
          gameName: "Aviator",
          image: "/img/TopNav/aviator.png",
        },
      ],
      isWebView: false,
      referralCode: null,
      homePageGame: [
        {
          providerID: 5,
          gameID: "JetX",
          gameName: "Jet X",
          image: "/img/home/JetX.jpg",
          crashGameUrl: "jetx",
        },
      ],
    };
  },
  components: {
    Footer,
    LanguageToggle,
  },
  mounted: function () {
    var vm = this;

    vm.myProfile = vm.getProfile();

    this.checkIfWebView();

    this.initMenuModal();
    this.getSports();
    setInterval(function () {
      vm.myProfile = vm.getProfile();
    }, 2000);
    this.EventBus.$on("deposit:popup", function (payload) {
      vm.amount = payload.amount;
      vm.message = payload.message;
      if (document.getElementById("deposit-init") != null) {
        document.getElementById("deposit-init").click();
      }
    });

    this.getTopHeaderHeight();

    this.EventBus.$on("init:mqtt", function () {
      console.log("Wants Inititalize MQTT");

      if (vm.mqttClient !== false) {
        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();
    });

    vm.initMqtt();

    var params = this.getURLParameters();

    var utm_source = params.utm_source ? params.utm_source : "";
    var utm_medium = params.utm_medium ? params.utm_medium : "";
    var utm_campaign = params.utm_campaign ? params.utm_campaign : "";
    var referral_code = params.p ? params.p : "";
    var referrer = document.referrer;
    var btag = params.btag ? params.btag : "";
    console.log("GOT referrer " + referrer);

    if (btag.length > 0) {
      this.setValue("btag", btag);
    }

    if (utm_source.length > 0) {
      this.setValue("utm_source", utm_source);
    }

    if (utm_medium.length > 0) {
      this.setValue("utm_medium", utm_medium);
    }

    if (referral_code.length > 0) {
      this.setValue("referral_code", referral_code);
    }

    if (utm_campaign.length > 0) {
      this.setValue("utm_campaign", utm_campaign);
    }
    this.setValue("referrer", referrer);

    if (this.$refs.topHeader) {
      window.addEventListener("resize", this.getTopHeaderHeight);
    }
  },

  unmounted: function () {
    window.removeEventListener("resize", this.getTopHeaderHeight);
  },

  computed: {
    showAppBanner: function () {
      if (this.$store.state.show_app_banner) {
        return "";
      } else {
        return "d-none";
      }
    },
    bal: function () {
      return this.formatCurrency(this.$store.state.balance);
    },
    full_name: function () {
      if (this.profile === undefined) {
        return "";
      }

      if (this.profile.f1 === undefined || this.profile.f1.length === 0) {
        return ms;
      }

      var fn = this.profile.f1;
      var mn = this.profile.f2;
      var ln = this.profile.f3;
      var ms = this.getUIValue(this.profile.m);
      return fn + " " + mn + " " + ln + " - " + ms;
    },
    profile: function () {
      return this.myProfile;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });

      return t;
    },
    homePageFontColor: function () {
      if (this.current_page === "home") {
        return "yellow-txt";
      }

      return "";
    },
    livePageFontColor: function () {
      if (this.current_page === "live") {
        return "yellow-txt";
      }

      return "";
    },
    historyPageFontColor: function () {
      if (this.current_page === "history") {
        return "yellow-txt";
      }

      return "";
    },
    betslip: function () {
      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.betslip;
    },
    activeBets: function () {
      var p = this.getProfile();

      if (!p) {
        return 0;
      }

      return p.b;
    },
    betslip_count: function () {
      if (!this.betslip.total || this.betslip.total == "") {
        return 0;
      }

      return parseInt(this.betslip.total);
    },
  },
  methods: {
    checkIfWebView() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      const isWebView =
        /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent) ||
        /\bwv\b/.test(userAgent) ||
        /Android.*Version\/[\d.]+.*(?:Chrome\/[\d.]+ Mobile Safari\/[\d.]+|wv)/i.test(
          userAgent
        );

      this.isWebView = isWebView;

      if (isWebView) {
        console.log("User is using a WebView.");
      } else {
        console.log("User is using a standard browser.");
      }
    },
    closeMenu() {
      document.getElementById("menu-modal").style.display = "none";
    },
    getReferrer() {
      return document.referrer || "No referrer found";
    },

    canStick: function () {
      if (
        this.$store.state.current_page == "casinogames" ||
        this.$store.state.current_page == "virtuals"
      ) {
        return "";
      } else {
        return "header-stick";
      }
    },
    canFeatureStick: function () {
      if (
        this.$store.state.current_page == "match" ||
        this.$store.state.current_page == "casinogames" ||
        this.$store.state.current_page == "casino" ||
        this.$store.state.current_page == "casino-live" ||
        this.$store.state.current_page == "virtuals"
      ) {
        return "";
      } else {
        return "feature-stick";
      }
    },
    isActive: function (page) {
      return this.current_page === page ? "active" : "";
    },

    setActive: function (menu) {
      this.current_menu = menu;
    },
    livescore: function () {},

    initMqtt: function () {
      /*
      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;
      var vm = this;


      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      // connection option

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      console.log('at endpoint ' + endpoint)

      var client = mqtt.connect(endpoint, options);

      client.on('connect', function () {

        console.log('connected here')

        if (vm.myProfile) {

          var profileTopic = 'topic/profile/' + vm.myProfile.id;
          profileTopic = profileTopic.toLowerCase();

          client.subscribe(profileTopic, function (err) {

            if (!err) {

              console.log('subscribed to topic ' + profileTopic);

            }

          });
        }

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      })

      this.mqttClient = client;

       */
    },

    getMarket: function (sport_id) {
      this.$store.dispatch("getMarkets", sport_id);
    },
    setSport: function (sport) {
      this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport.sport_id);
      this.goHome();
    },
    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id);
      this.$router.push({ name: "sport", params: {} });
    },

    initMenuModal: function () {
      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
        document.body.style.position = "";
      };

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        //document.body.style.position = 'fixed';
      };

      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("menu-modal")) {
          modal.style.display = "none";
        }
      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },

    initDeposit: function () {
      this.dismiss();
      this.$router.push({ name: "deposit" });
    },
    dismiss: function () {
      console.log("DISMISS MENU");
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";
    },

    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "sport-" + index + "-");
    },
    getSports: function () {
      this.$store.dispatch("getSports");
    },

    setAmount: function (amount) {
      this.amount = parseFloat(amount).toFixed(2);
      this.deposit();
    },

    deposit: function () {
      this.reset();
      var p = this.getProfile();

      if (!p) {
        this.$toast.open({
          message: `${this.$t("pleaseLoginProceed")}`,
          type: "error",
          position: "top",
        });

        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 1) {
        this.$toast.open({
          message: `${this.$t("enterAtLeast")} ${this.currency}. 10 ${this.$t(
            "orAbove"
          )}`,
          type: "error",
          position: "top",
        });
        return;
      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: document.referrer,
      };

      vm.loading = "loading";

      axios
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.message;
          vm.setSuccess("Deposit Initiated", msg);
          vm.message = "Please enter amount you want to deposit";
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.status) === 401 ||
              parseInt(err.response.status) === 400 ||
              parseInt(err.response.status) === 428
            ) {
              this.$toast.open({
                message: "Your session on this device has expired",
                type: "error",
                position: "top",
              });

              vm.logout();
              return;
            } else {
              this.$toast.open({
                message: err.response.data.message,
                type: "error",
                position: "top",
              });

              console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });

            console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            console.log(JSON.stringify(err));
          }
        });
    },

    showDeposit: function () {
      document.getElementById("deposit-init").click();
    },
    searchVisible: function () {
      console.log("this.current_page ==> " + this.current_page);

      var searchVisible = this.current_page !== "search";
      console.log(
        "this.current_page ==> " +
          this.current_page +
          " searchVisible ==> " +
          searchVisible
      );

      return searchVisible;
    },
    getTopHeaderHeight: function () {
      const height = this.$refs.topHeader?.clientHeight;

      //set root css variable
      document.documentElement.style.setProperty(
        "--top-header-height",
        (height ?? 0) + "px"
      );
    },
  },
  created() {
    this.$store.dispatch("fetchReferralCode");
  },
  beforeDestroy: function () {
    console.log("beforeDestroy");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>

<style scoped>
.icon-container {
  /* background-color: #f7d9d9; */
  /* width: 60px;
  height: 60px; */
  /* display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center; */
}

.crash-icon-container {
  /* background-color: #f7d9d9; */
  /* width: 60px;
  height: 60px; */
  /* display: flex;
  border-radius: 6px;
  justify-content: center;
  align-items: center; */
}

.header {
  padding: 0.5rem 20px;
}

.logo {
  flex-grow: 1;
  gap: 5px;
  align-items: center;
}

.top-header {
  position: sticky;
}

.side-menu-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flatlink-div {
  padding: 12px 20px;
}
</style>
