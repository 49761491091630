<template>
  <div class="flex relative items-center justify-end">
    <div class="relative z-10 items-start">
      <div
        class="d-flex gap-2 cursor-pointer mb-1 align-items-center toggle"
        @click="toggleDropdown"
      >
        <GlobalIcon class="mr-1" />
        <ArrowDown/>
      </div>
      <div
        v-if="isDropdownVisible"
        class="dropdown absolute left-0 right-0 left-[10px] md:left-[20px]"
      >
        <p
          v-for="item in langList"
          :key="item.type"
          class="dropdown-item text-sm flex cursor-pointer rounded-lg hover:bg-[#eeeeee]"
          @click="changeLanguage(item.locale)"
        >
          <span class="text-sm capitalize md:text-sm">
            {{ item.language }}
          </span>
          <TickCircle v-if="currentLang === item.locale" class="w-2 h-2" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalIcon from '@/components/icons/GlobalIcon';
import ArrowDown from '@/components/icons/CaretDownIcon';
import TickCircle from '@/components/icons/TickCircleIcon.vue';

export default {
  components: {
    GlobalIcon,
    ArrowDown,
    TickCircle,
  },
  data() {
    return {
      langList: [
        { type: 'en', locale: 'en', language: this.$t('English') },
        { type: 'fr', locale: 'fr', language: this.$t('French') },
      ],
      currentLang: this.$i18n.locale,
      isDropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    changeLanguage(lang) {
      localStorage.setItem('locale', lang);
      this.currentLang = lang;
      this.$i18n.locale = lang;
      this.isDropdownVisible = false;
    },
  },
};
</script>

<style scoped>
.toggle {
  /* width: 50px; */
  max-height: 50px;
}

.dropdown {
  max-height: 200px;
  overflow-y: auto;
  width: 110px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 45px;
}

.dropdown-item {
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  /* svg {
    margin-bottom: -6px;
  } */
}
</style>
