import AppHome from './components/AppHome'
import BustaPesa from "./components/BustaPesa";

const Home = () => import('./components/Home')
const Sport = () => import('./components/Sport')

const FixturesBySport = () => import('./components/FixturesBySport')
const Esport = () => import('./components/Esport')
const Print = () => import('./components/Print')
const PrintGame = () => import('./components/PrintGame')
const Tips = () => import('./components/Tips')
const Sharebet = () => import('./components/Sharebet')
const Offers = () => import('./components/Offers')
const Live = () => import('./components/Live')
const MobileApp = () => import("./components/MobileApp");
const Match = () => import('./components/Match')
const AviatorLogin = () => import('./components/AviatorLogin')
const Login = () => import('./components/Login')
const ProfileUpdate = () => import('./components/ProfileUpdate')
const ProfileUpdated = () => import('./components/ProfileUpdated')
const PasswordUpdate = () => import('./components/PasswordUpdate')
const CustomerSupport = () => import('./components/CustomerSupport')
const Inbox = () => import('./components/Inbox')
const LanguageSetting = () => import('./components/LanguageSetting')
const Join = () => import('./components/Join')
const Reset = () => import('./components/Reset')
const Deposit = () => import('./components/Deposit')
const DepositFunds = () => import('./components/DepositFunds')
const WithdrawFunds = () => import('./components/WithdrawFunds')
const WaveDeposit = () => import('./components/WaveDeposit')
const OrangeDeposit = () => import('./components/OrangeDeposit')
const MtnDeposit = () => import('./components/MtnDeposit')
const MoovDeposit = () => import('./components/MoovDeposit')

const WaveWithdraw = () => import('./components/WaveWithdraw')
const OrangeWithdraw = () => import('./components/OrangeWithdraw')
const MtnWithdraw = () => import('./components/MtnWithdraw')
const MoovWithdraw = () => import('./components/MoovWithdraw')

const DepositHowTo = () => import('./components/DepositHowTo')
const Setting = () => import('./components/Setting')
const Bets = () => import('./components/Bets')
const MyBets = () => import('./components/MyBets')
const Terms = () => import('./components/Terms')
const Privacy = () => import('./components/Privacy')
const Rules = () => import('./components/Rules')
const Faq = () => import('./components/Faq')
const Freebet = () => import("@/components/Freebet");
const Search = () => import("./components/Search");
const Countries = () => import("@/components/Countries");
const Outright = () => import("@/components/outright/Index");
const Today = () => import("@/components/Today");
const Tomorrow = () => import("@/components/Tomorrow");
const Upcoming = () => import("@/components/Upcoming");
const Share = () => import("@/components/Share");
const Invite = () => import("@/components/Invite");
const Help = () => import("./components/Help");
const Responsible = () => import("./components/Responsible");
const Booklet = () => import("./components/Booklet");
const Jackpot = () => import("./components/jackpot/Jackpot");
const Results = () => import("./components/Results");
const Livescore = () => import("./components/Livescore");
const Profile = () => import("./components/Profile");
const Gift = () => import("./components/Gift");
const SelfExclusion = () => import("./components/SelfExclusion");
const FormulaOne = () => import("./components/FormulaOne");

//casino menus
const CasinoGames = () => import("./components/casino/CasinoGames");
const Casino = () => import("./components/casino/Casino");
const Virtuals = () => import("./components/casino/Virtuals");
const LiveCasino = () => import("./components/casino/Live");
const Fastballs = () => import("./components/casino/Fastballs");
const Smartsoft = () => import("./components/casino/Smartsoft");
const Jetsafi = () => import("./components/casino/Jetsafi");
const Booming = () => import("./components/casino/Booming");
const Stp = () => import("./components/casino/Stp");
const Onlyplay = () => import("./components/casino/Onlyplay");

//
const Rocketman = () => import("./components/casino/Rocketman");
const MonkeyBizniz = () => import("./components/casino/MonkeyBizniz");
const Aviator = () => import("./components/casino/Aviator");
const Comet = () => import("./components/casino/Comet");
const JetX = () => import("./components/casino/JetX");
const FootballX = () => import("./components/casino/FootballX");
const StreetDice = () => import("./components/casino/StreetDice");
const Spin2Win = () => import("./components/casino/Spin2Win");
const AztecGems = () => import("./components/casino/AztecGems");
const Plinko = () => import("./components/casino/Plinko");
export const routes = [

    {
        path: '/',
        name: 'sports',
        component: Sport,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/'
        }
    },    
    {
        path: '/crash',
        name: 'home',
        component: Home,
        meta: {
            title: 'Choplife - Your Ultimate Betting Destination',
            ogTitle: 'Choplife - Your Ultimate Betting Destination',
            description: 'Welcome to Choplife, the ultimate online betting platform. Enjoy top-notch sports betting, exciting casino games, live betting options, and more!',
            ogDescription: 'Welcome to Choplife, the ultimate online betting platform. Enjoy top-notch sports betting, exciting casino games, live betting options, and more!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/a'
        }
    },
    {
        path: "/sports/football",
        name: "football",
        component: Home,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/a'
        }
    },
    {
        path: '/sport/:sport',
        name: 'sport',
        component: Sport,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/sport/:sport'
        }
    },
    {
        path: '/a',
        name: 'apphome',
        component: AppHome,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/a'
        }
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/search'
        }
    },
    {
        path: '/sports/:sport_name/:sport_id',
        name: 'fixturesbysport',
        component: FixturesBySport,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/sports/:sport_name/:sport_id'
        }
    },
    {
        path: '/live',
        name: 'live',
        component: Live,
        meta: {
            title: 'Choplife Sports - Bet on a Wide Range of Sports',
            ogTitle: 'Choplife Sports - Bet on a Wide Range of Sports',
            description: 'Bet on your favorite sports with Choplife. From football to basketball, enjoy a comprehensive range of sports betting options with competitive odds and exciting promotions.',
            ogDescription: 'Bet on your favorite sports with Choplife. From football to basketball, enjoy a comprehensive range of sports betting options with competitive odds and exciting promotions.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/live'
        }
    },
    {
        path: '/freebet',
        name: 'freebet',
        component: Freebet,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/'
        }
    },
    {
        path: '/today',
        name: 'today',
        component: Today,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/today'
        }
    },

    {
        path: '/tomorrow',
        name: 'tomorrow',
        component: Tomorrow,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/tomorrow'
        }
    },

    {
        path: '/upcoming',
        name: 'upcoming',
        component: Upcoming,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/upcoming'
        }
    },
    {
        path: '/esport',
        name: 'esport',
        component: Esport,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/esport'
        }
    },
    {
        path: "/match/:status/:game_id/:name",
        name: "match",
        component: Match,
    },
    {
        path: '/share/:code',
        name: 'share',
        component: Share,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com//share/:code'
        }
    },
    {
        path: '/invite/:code',
        name: 'invite',
        component: Invite,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/invite/:code'
        }
    },
    {
        path: '/join',
        name: 'join',
        component: Join,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/join'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/login'
        }
    },
    {
        path: '/profile-update',
        name: 'profile-update',
        component: ProfileUpdate,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/login'
        }
    },
    {
        path: '/profile-updated',
        name: 'profile-updated',
        component: ProfileUpdated,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/login'
        }
    },
    {
        path: '/password-update',
        name: 'password-update',
        component: PasswordUpdate,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/login'
        }
    },
    {
        path: '/customer-support',
        name: 'customer-support',
        component: CustomerSupport,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/login'
        }
    },
    {
        path: '/inbox',
        name: 'inbox',
        component: Inbox,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/login'
        }
    },
    {
        path: '/language',
        name: 'language',
        component: LanguageSetting,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/login'
        }
    },
    {
        path: '/aviatorlogin',
        name: 'aviatorlogin',
        component: AviatorLogin,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/login'
        }
    },
    {
        path: '/print',
        name: 'print',
        component: Print,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/print'
        }
    },

    {
        path: '/print-game',
        name: 'print-game',
        component: PrintGame,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/print-game'
        }
    },
    {
        path: '/deposit',
        name: 'deposit',
        component: Deposit,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/deposit'
        }
    },
    {
        path: '/deposit-funds',
        name: 'deposit',
        component: DepositFunds,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/deposit'
        }
    },
    {
        path: '/withdraw-funds',
        name: 'withdraw',
        component: WithdrawFunds,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/withdraw'
        }
    },
    {
        path: '/wave-deposit',
        name: 'wave-deposit',
        component: WaveDeposit,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/deposit'
        }
    },
    {
        path: '/orange-deposit',
        name: 'orange-deposit',
        component: OrangeDeposit,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/deposit'
        }
    },
    {
        path: '/mtn-deposit',
        name: 'mtn-deposit',
        component: MtnDeposit,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/deposit'
        }
    },
    {
        path: '/moov-deposit',
        name: 'moov-deposit',
        component: MoovDeposit,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/deposit'
        }
    },



    {
        path: '/wave-withdraw',
        name: 'wave-withdraw',
        component: WaveWithdraw,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Withdraw bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/withdraw'
        }
    },
    {
        path: '/orange-withdraw',
        name: 'orange-withdraw',
        component: OrangeWithdraw,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Withdraw bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/withdraw'
        }
    },
    {
        path: '/mtn-withdraw',
        name: 'mtn-withdraw',
        component: MtnWithdraw,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Withdraw bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/withdraw'
        }
    },
    {
        path: '/moov-withdraw',
        name: 'moov-withdraw',
        component: MoovWithdraw,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/withdraw'
        }
    },

    {
        path: '/countries',
        name: 'country',
        component: Countries,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/countries'
        }
    },
    {
        path: '/outrights/:type',
        name: 'outright',
        component: Outright,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/outrights/:type'
        }
    },
    {
        path: '/setting',
        name: 'setting',
        component: Setting,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/setting'
        }
    },
    {
        path: '/my-account',
        name: 'my-account',
        component: Setting,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/my-account'
        }
    },
    {
        path: '/my-bets',
        name: 'bets',
        component: Bets,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/my-bets'
        }
    },
    {
        path: '/my-profile-bets',
        name: 'bets',
        component: MyBets,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/my-bets'
        }
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms,
        meta: {
            hideMenu: true,
            title: 'Choplife Terms and Conditions - Legal Information',
            ogTitle: 'Choplife Terms and Conditions - Legal Information',
            description: 'Read Choplife\'s Terms and Conditions to understand the legal agreements and policies governing the use of our online betting platform. Ensure you are informed about our terms before using our services.',
            ogDescription: 'Read Choplife\'s Terms and Conditions to understand the legal agreements and policies governing the use of our online betting platform. Ensure you are informed about our terms before using our services.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/terms'
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy,
        meta: {
            hideMenu: true,
            title: 'Choplife Privacy and Conditions - Legal Information',
            ogTitle: 'Choplife Privacy and Conditions - Legal Information',
            description: 'Read Choplife\'s Privacy and Conditions to understand the legal agreements and policies governing the use of our online betting platform. Ensure you are informed about our Privacy before using our services.',
            ogDescription: 'Read Choplife\'s Privacy and Conditions to understand the legal agreements and policies governing the use of our online betting platform. Ensure you are informed about our Privacy before using our services.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/privacy'
        }
    },
    {
        path: '/rules',
        name: 'rules',
        component: Rules,
        meta: {
            hideMenu: true,
            title: 'Choplife rules and Conditions - Legal Information',
            ogTitle: 'Choplife rules and Conditions - Legal Information',
            description: 'Read Choplife\'s rules and Conditions to understand the legal agreements and policies governing the use of our online betting platform. Ensure you are informed about our rules before using our services.',
            ogDescription: 'Read Choplife\'s rules and Conditions to understand the legal agreements and policies governing the use of our online betting platform. Ensure you are informed about our rules before using our services.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/rules'
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        meta: {
            hideMenu: true,
            title: 'Choplife FAQs - Frequently Asked Questions',
            ogTitle: 'Choplife FAQs - Frequently Asked Questions',
            description: 'Find answers to frequently asked questions about Choplife\'s betting services. Get help with common queries and learn more about our platform\'s features and policies.',
            ogDescription: 'Find answers to frequently asked questions about Choplife\'s betting services. Get help with common queries and learn more about our platform\'s features and policies.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/faq'
        }
    },
    {
        path: '/gifts',
        name: 'gifts',
        component: Gift,
        meta: {
            title: 'Choplife Promotions - Exciting Bonuses and Offers',
            ogTitle: 'Choplife Promotions - Exciting Bonuses and Offers',
            description: 'Discover the latest promotions and bonuses on Choplife Get free bets, cashback, and more. Don\'t miss out!',
            ogDescription: 'Discover the latest promotions and bonuses on Choplife Get free bets, cashback, and more. Don\'t miss out!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/gifts'
        }
    },
    {
        path: '/selfexclusion',
        name: 'selfexclusion',
        component: SelfExclusion,
        meta: {
            title: 'Choplife Promotions - Exciting Bonuses and Offers',
            ogTitle: 'Choplife Promotions - Exciting Bonuses and Offers',
            description: 'Discover the latest promotions and bonuses on Choplife Get free bets, cashback, and more. Don\'t miss out!',
            ogDescription: 'Discover the latest promotions and bonuses on Choplife Get free bets, cashback, and more. Don\'t miss out!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/gifts'
        }
    },
    {
        path: '/deposit-how-to',
        name: 'deposit-how-to',
        component: DepositHowTo,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/deposit-how-to'
        }
    },
    {
        path: '/mobile-app',
        name: 'mobile-app',
        component: MobileApp,
    },
    {
        path: '/howto',
        name: 'help',
        component: Help,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/howto'
        }
    },
    {
        path: '/responsible-gaming',
        name: 'responsible',
        component: Responsible,
        meta: {
            hideMenu: true,
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/responsible-gaming'
        }
    },
    {
        path: '/reset/:msisdn/:code',
        name: 'reset',
        component: Reset,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/reset/:msisdn/:code'
        }
    },
    {
        path: '/booklet',
        name: 'booklet',
        component: Booklet,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/booklet'
        }
    },
    {
        path: '/jackpot-games',
        name: 'jackpot',
        component: Jackpot,
        meta: {
            title: 'Choplife Jackpot - Win Big with Our Jackpot Games',
            ogTitle: 'Choplife Jackpot - Win Big with Our Jackpot Games',
            description: 'Try your luck with Choplife Jackpot games. Discover a range of jackpot slots and games with huge prizes and the chance to win big. Join now for exciting opportunities!',
            ogDescription: 'Try your luck with Choplife Jackpot games. Discover a range of jackpot slots and games with huge prizes and the chance to win big. Join now for exciting opportunities!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/jackpot-games'
        }
    },
    {
        path: '/tips',
        name: 'tips',
        component: Tips,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/tips'
        }
    },
    {
        path: '/sharebet',
        name: 'sharebet',
        component: Sharebet,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/sharebet'
        }
    },
    {
        path: '/chopLife',
        name: 'chopLife',
        component: BustaPesa,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/chopLife'
        }
    },
    {
        path: '/formula-1',
        name: 'formula1',
        component: FormulaOne,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/formula-1'
        }
    },
    {
        path: '/offers',
        name: 'offers',
        component: Offers,
        meta: {
            title: 'Choplife Promotions - Exciting Bonuses and Offers',
            ogTitle: 'Choplife Promotions - Exciting Bonuses and Offers',
            description: 'Discover the latest promotions and bonuses on Choplife Get free bets, cashback, and more. Don\'t miss out!',
            ogDescription: 'Discover the latest promotions and bonuses on Choplife Get free bets, cashback, and more. Don\'t miss out!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/offers'
        }
    },
    {
        path: '/results',
        name: 'results',
        component: Results,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/results'
        }
    },
    {
        path: '/livescore',
        name: 'livescore',
        component: Livescore,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/livescore'
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/profile'
        }
    },
    {
        path: '*',
        name: '404',
        component: Home,
        meta: {
            title: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            ogTitle: 'Choplife - Africa\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with Choplife.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/404'
        }
    },
    //    casino games
    {
        path: '/casino',
        name: 'casino',
        component: Casino,
        meta: {
            title: 'Choplife Casino - Play Exciting Casino Games and Slots',
            ogTitle: 'Choplife Casino - Play Exciting Casino Games and Slots',
            description: 'Explore Choplife Casino for a thrilling experience with a variety of games including slots, poker, and table games. Discover exciting promotions and bonuses for endless fun!',
            ogDescription: 'Explore Choplife Casino for a thrilling experience with a variety of games including slots, poker, and table games. Discover exciting promotions and bonuses for endless fun!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/casino'
        }
    },
    {
        path: '/virtuals',
        name: 'virtuals',
        component: Virtuals,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/virtuals'
        }
    },
    {
        path: '/casino/live',
        name: 'livecasino',
        component: LiveCasino,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/casino/live'
        }
    },
    {
        path: '/casino-games',
        name: 'casinogames',
        component: CasinoGames,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/casino-games'
        }
    },
    {
        path: '/rocketman',
        name: 'rocketman',
        component: Rocketman,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/rocketman'
        }
    },
    {
        path: '/monkeybizniz',
        name: 'monkeybizniz',
        component: MonkeyBizniz,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/rocketman'
        }
    },
    {
        path: '/aviator',
        name: 'aviator',
        component: Aviator,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/aviator'
        }
    },
    {
        path: '/comet',
        name: 'comet',
        component: Comet,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/comet'
        }
    },
    {
        path: '/jetx',
        name: 'jetx',
        component: JetX,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/jetx'
        }
    },
    {
        path: '/footballx',
        name: 'footballx',
        component: FootballX,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/footballx'
        }
    },
    {
        path: '/streetdice',
        name: 'streetdice',
        component: StreetDice,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/streetdice'
        }
    },
    {
        path: '/spin2win',
        name: 'spin2win',
        component: Spin2Win,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/spin2win'
        }
    },
    {
        path: '/aztecgems',
        name: 'aztecgems',
        component: AztecGems,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/aztecgems'
        }
    },
    {
        path: '/plinko',
        name: 'plinko',
        component: Plinko,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/plinko'
        }
    },
    {
        path: '/fastballs',
        name: 'fastballs',
        component: Fastballs,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/fastballs'
        }
    },
    {
        path: '/smartsoft',
        name: 'smartsoft',
        component: Smartsoft,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/smartsoft'
        }
    },
    {
        path: '/jetsafi',
        name: 'jetsafi',
        component: Jetsafi,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/jetsafi'
        }
    },
    {
        path: '/stp',
        name: 'stp',
        component: Stp,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/stp'
        }
    },
    {
        path: '/booming',
        name: 'Booming',
        component: Booming,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/booming'
        }
    },
    {
        path: '/onlyplay',
        name: 'Onlyplay',
        component: Onlyplay,
        meta: {
            title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'Choplife Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Africa betting, Africa sports betting, Bet in Africa, African betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: 'https://chopLife.com/android-chrome-512x512.png',
            ogUrl: 'https://chopLife.com/onlyplay'
        }
    },

];