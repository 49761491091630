<template>
  <footer
    v-if="!$route.meta.hideMenu"
    style="border-top: solid var(--light-gray) 1px"
    class="text-lg-start bg-body-tertiary text-muted"
  >
    <section class="">
      <div class="container text-md-start">
        <div class="d-flex align-items-left px-0 pt-1 pb-1 m-0 text-left logo">
          <router-link to="/">
            <!-- <img
              style="float: left; width: 114px"
              src="/img/chopLifeLogo.svg"
              class="top-header-logo img-fluid"
              alt="Choplife Logo"
            /> -->
            <img
              style="float: left; height: 24px; width: 114px"
              src="/img/chopLifeLogo.svg"
              class="top-header-logo img-fluid"
              alt="Chopbet Logo"
            />
          </router-link>
        </div>

        <div class="mt-4 mb-4" style="color: black">
          <h6>
            <strong> {{ $t('helpCenter') }}</strong>
          </h6>
        </div>

        <div class="mt-4 mb-4" style="color: black">
          <h6>
            <strong> {{ $t('contactSupport') }}</strong>
          </h6>
        </div>

        <div class="mt-4 mb-4" style="color: black">
          <h6>
            <strong>{{ $t('termsConditions') }}</strong>
          </h6>
        </div>
        <div class="mt-4 mb-4" style="color: black">
          <h6>
            <strong>{{ $t('privacyPolicy') }}</strong>
          </h6>
        </div>
        <div class="responsible-gaming">
          <div style="color: black">
            <h6>
              <strong>{{ $t('responsibleGaming') }}</strong>
            </h6>
          </div>

          <div style="color: #383636">
            <p>
              {{ $t('thisRealGamble') }}
              <strong>
                <router-link style="color: var(--red)" to="/">
                  {{ $t('here') }}</router-link
                > </strong
              >.
            </p>
          </div>
        </div>

        <div class="mt-4 mb-4" style="color: #383636">
          <div class="d-flex align-items-center">
            <h6>
              <strong>
                {{ $t('licensed') }}
              </strong>
            </h6>
            <div class="must-be-18">
              <p>18+</p>
            </div>
          </div>
          <p>
            {{ $t('mustBe18Years') }}
          </p>
        </div>
      </div>
    </section>

    <div class="socials" style="color: black">
      <a href="https://t.me/choplifeci" class="footer-link title-text">
        <img src="/img/home/telegram.png" alt="Choplife telegram" />
      </a>
      <a
        href="https://m.facebook.com/choplife.ci/"
        class="footer-link title-text"
      >
        <img src="/img/home/facebook.png" alt="Choplife facebook" />
      </a>
      <a
        href="https://www.instagram.com/choplife.ci_?igsh=MTl3YnBqZHRxdzFveQ=="
        class="footer-link title-text"
      >
        <img src="/img/home/instagram.png" alt="Choplife instagram" />
      </a>
      <a
        href="https://x.com/Choplife_CI?t=ViuwoktlWf_3C6m6Ke0_QA&s=09"
        class="footer-link title-text"
      >
        <img src="/img/home/x.png" alt="Choplife x" />
      </a>
    </div>

    <!-- <div class="mt-4 mb-4" style="color: black;">
      <select 
        style="max-width: 300px;border: solid 1px black;margin: 5px;" 
        class="form-control form-select mb-3"
        @change="changeLanguage($event)">
        <option selected>{{ $t('select_language') }}</option>
        <option value="en">{{ $t('english') }}</option>
        <option value="fr">{{ $t('french') }}</option>
      </select>
    </div> -->

    <div class="text-left p-4 text-dark" style="">
      © Copyright: Choplife. All rights reserve
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
  // methods: {
  //   changeLanguage(event) {
  //     const selectedLanguage = event.target.value;
  //     this.$i18n.locale = selectedLanguage; // Change the locale dynamically
  //   }
  // }
};
</script>

<style scoped>
.container {
  padding: 40px 20px 0;
}
footer {
  padding-bottom: 100px;
}
.socials {
  padding: 20px 20px 40px;
  display: flex;
  gap: 24px;
}

.socials .footer-link {
  border: 1px solid #cc1717;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.socials .footer-link img {
  widows: 16.67px;
  height: 16.67px;
}

h6 {
  font-size: 14px;
}
.responsible-gaming {
  margin-top: 25px;
}
.must-be-18 {
  background-color: #fcf2f2;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 100%;
  margin-bottom: 7px;
  margin-left: 5px;
}
.must-be-18 p {
  font-weight: 700;
  display: inline-block;
  color: #cc1717;
  margin: 0;
}
</style>
